import {
  snapshotToTyped,
  UserType,
  type BrandUser,
  type User,
} from '@tn/shared';
import type { UserCredential } from 'firebase/auth';
import { collection, doc, getDoc } from 'firebase/firestore';

export default async function useLoggedInUserDetails(
  user: UserCredential['user']
) {
  let claims: any = {};
  let brandUser;
  let memberUser;

  if (user) {
    const tokenResult = await user.getIdTokenResult();
    claims = tokenResult.claims;
  }
  const userType: any = claims.user_type ?? UserType.Member;
  const isBrand = userType === UserType.Brand;
  const db = useFirestore();

  if (isBrand) {
    const brandUserRef = doc(collection(db, 'brandUsers'), user.uid);
    const brandUserSnap = await getDoc(brandUserRef);

    if (brandUserSnap.exists()) {
      brandUser = snapshotToTyped<BrandUser>(brandUserSnap);
    }
  } else {
    const userRef = doc(collection(db, 'users'), user.uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      memberUser = snapshotToTyped<User>(userSnap);
    }
  }

  useState('uid').value = user.uid;
  useState('email').value = user.email;
  useState('userType').value = userType;
  useState('isBrand').value = isBrand;
  useState('brandUser').value = brandUser;
  useState('memberUser').value = memberUser;

  return {
    uid: user.uid,
    userType,
    claims,
    isBrand,
    memberUser,
    brandUser,
  };
}
